const DOCUMENT = 'COURSE_';

const actions = {
  LOAD_FROM_API: `${DOCUMENT}LOAD_FROM_API`,
  LOAD_FROM_API_SUCCESS: `${DOCUMENT}LOAD_FROM_API_SUCCESS`,
  LOAD_FROM_API_ERROR: `${DOCUMENT}LOAD_FROM_API_ERROR`,

  SAVE_TO_API: `${DOCUMENT}SAVE_TO_API`,
  SAVE_TO_API_SUCCESS: `${DOCUMENT}SAVE_TO_API_SUCCESS`,
  SAVE_TO_API_ERROR: `${DOCUMENT}SAVE_TO_API_ERROR`,

  REMOVE_FROM_API: `${DOCUMENT}REMOVE_FROM_API`,
  REMOVE_FROM_API_SUCCESS: `${DOCUMENT}REMOVE_FROM_API_SUCCESS`,
  REMOVE_FROM_API_ERROR: `${DOCUMENT}REMOVE_FROM_API_ERROR`,

  SET_PAGE: `${DOCUMENT}SET_PAGE`,
  SET_ITEMS_PER_PAGE: `${DOCUMENT}SET_ITEMS_PER_PAGE`,
  SET_ORDER_BY: `${DOCUMENT}SET_ORDER_BY`,
  SET_ORDER_BY_DIRECTION: `${DOCUMENT}SET_ORDER_BY_DIRECTION`,
  SET_FILTER: `${DOCUMENT}SET_FILTER`,

  LOAD_NEXT_STREAMS: `${DOCUMENT}LOAD_NEXT_STREAMS`,
  LOAD_NEXT_STREAMS_SUCCESS: `${DOCUMENT}LOAD_NEXT_STREAMS_SUCCESS`,

  BUY_STREAM_SUBSCRIPTION: `${DOCUMENT}BUY_STREAM_SUBSCRIPTION`,

  BUY_INDIVIDUAL_LESSON: `${DOCUMENT}BUY_INDIVIDUAL_LESSON`,

  BOOKED_STREAM: `${DOCUMENT}BOOKED_STREAM`,

  loadFromApi: () => {
    return {
      type: actions.LOAD_FROM_API,
    };
  },

  loadFromApiSuccess: (data, subjects, lessons, streams, totalItems) => ({
    type: actions.LOAD_FROM_API_SUCCESS,
    payload: {
      data,
      subjects,
      lessons,
      streams,
      totalItems,
    },
  }),

  loadFromApiError: (error) => ({
    type: actions.LOAD_FROM_API_ERROR,
    payload: {
      error,
    },
  }),

  loadNextStreams: (id) => {
    return {
      type: actions.LOAD_NEXT_STREAMS,
      payload: {
        id,
      },
    };
  },
  loadNextStreamsSuccess: (streams, subscriptions, exercisesCount) => {
    return {
      type: actions.LOAD_NEXT_STREAMS_SUCCESS,
      payload: {
        streams,
        subscriptions,
        exercisesCount,
      },
    };
  },
  removeFromApi: (id) => {
    return {
      type: actions.REMOVE_FROM_API,
      payload: {
        id,
      },
    };
  },

  removeFromApiSuccess: (item) => ({
    type: actions.REMOVE_FROM_API_SUCCESS,
    payload: {
      item,
    },
  }),

  removeFromApiError: (error) => ({
    type: actions.REMOVE_FROM_API_ERROR,
    payload: {
      error,
    },
  }),

  saveToApi: (item) => ({
    type: actions.SAVE_TO_API,
    payload: {
      item,
    },
  }),

  saveToApiSuccess: (item) => ({
    type: actions.SAVE_TO_API_SUCCESS,
    payload: {
      item,
    },
  }),

  saveToApiError: (error) => ({
    type: actions.SAVE_TO_API_ERROR,
    payload: {
      error,
    },
  }),

  setPage: (page) => ({
    type: actions.SET_PAGE,
    payload: {
      page,
    },
  }),

  setItemsPerPage: (itemsPerPage) => ({
    type: actions.SET_ITEMS_PER_PAGE,
    payload: {
      itemsPerPage,
    },
  }),

  setOrderBy: (orderBy) => ({
    type: actions.SET_ORDER_BY,
    payload: {
      orderBy,
    },
  }),

  setOrderByDirection: (orderByDirection) => ({
    type: actions.SET_ORDER_BY_DIRECTION,
    payload: {
      orderByDirection,
    },
  }),

  setFilter: (filter, value) => ({
    type: actions.SET_FILTER,
    payload: {
      filter,
      value,
    },
  }),

  buyStreamSubscription: (subscriptionId, cost) => ({
    type: actions.BUY_STREAM_SUBSCRIPTION,
    payload: {
      subscriptionId,
      cost,
    },
  }),

  buyIndividualLesson: (subscriptionId, streamId, cost, data) => ({
    type: actions.BUY_INDIVIDUAL_LESSON,
    payload: {
      subscriptionId,
      streamId,
      cost,
      data,
    },
  }),

  bookingStream: (streamId, cost) => ({
    type: actions.BOOKED_STREAM,
    payload: {
      streamId,
      cost,
    },
  }),
};

export default actions;
