import { combineReducers } from 'redux';
import settings from './settings/reducer';
import menu from './menu/reducer';
import authUser from './auth/reducer';
import todoApp from './todo/reducer';
import chatApp from './chat/reducer';
import surveyListApp from './surveyList/reducer';
import surveyDetailApp from './surveyDetail/reducer';
import course from './course/reducer';
import courseVault from './courseVault/reducer';
import mediaVault from './mediaVault/reducer';
import user from './user/reducer';
import userVault from './userVault/reducer';
import userRating from './userRating/reducer';
import userRatingVault from './userRatingVault/reducer';
import profile from './profile/reducer';
import operationVault from './operationVault/reducer';
import operation from './operation/reducer';
import lessonChat from './lessonChat/reducer';
import lessonStudentPage from './lessonStudentPage/reducer';
import lessonTeacherPage from './lessonTeacherPage/reducer';

const reducers = combineReducers({
  menu,
  settings,
  authUser,
  todoApp,
  chatApp,
  surveyListApp,
  surveyDetailApp,
  course,
  courseVault,
  mediaVault,
  user,
  userVault,
  userRating,
  userRatingVault,
  profile,
  operationVault,
  operation,
  lessonChat,
  lessonStudentPage,
  lessonTeacherPage,
});

export default reducers;
