import { all, takeEvery, put, call, select } from "redux-saga/effects";
import { NotificationManager } from "components/common/react-notifications";
import api from "api";
import actions from "./actions";

const COLLECTION_NAME = "operation"; // change your collection

const getUser = (state) => state.profile.user;

function* getItem({ payload }) {
  try {
    const user = yield select(getUser);

    const params = {
      query: {
        id: payload.id,
        $select: ["id", "transactionNum", "typeId", "userId", "time", "amount"],
        include: [
          {
            model: "operation_type",
            as: "type",
            attributes: ["id", "name", "direction"],
          },
          {
            model: "user_subscription",
            as: "userSubscription",
            attributes: ["id", "subscriptionId"],
            include: [
              {
                model: "subscription",
                as: "subscription",
                attributes: [
                  "id",
                  "streamId",
                  "exerciseCount",
                  "is_individual",
                  "is_full",
                  "is_free",
                ],
                include: [
                  {
                    model: "stream",
                    as: "stream",
                    attributes: ["id", "courseId"],
                    include: [
                      {
                        model: "course",
                        as: "course",
                        attributes: ["id", "name"],
                        include: [
                          {
                            model: "exercise",
                            as: "exercises",
                            attributes: ["id"],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            model: "stream_booking",
            as: "streamBooking",
            attributes: ["id", "streamId"],
            include: [
              {
                model: "stream",
                as: "stream",
                attributes: ["id", "courseId"],
                include: [
                  {
                    model: "course",
                    as: "course",
                    attributes: ["id", "name"],
                    include: [
                      {
                        model: "exercise",
                        as: "exercises",
                        attributes: ["id"],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    };

    const data = yield call(() => api.service(COLLECTION_NAME).findOne(params));

    yield put(actions.getItemSuccess(data));
  } catch (error) {
    NotificationManager.error(
      error.message,
      error.name,
      10000,
      null,
      null,
      "filled"
    );

    yield put(actions.getItemError());
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actions.GET_ITEM, getItem)]);
}
