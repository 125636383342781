import { all, takeEvery, put, call, select } from 'redux-saga/effects';
import api from 'api';
import actions from './actions';
import { updateUser as updateAuthUser } from '../auth/actions';
import profileActions from 'redux/profile/actions';

const COLLECTION_NAME = 'user'; // change your collection

const getPage = (state) => state.user.page;
const getItemsPerPage = (state) => state.user.itemsPerPage;
const getOrderBy = (state) => state.user.orderBy;
const getOrderByDirection = (state) => state.user.orderByDirection;
const getFilters = (state) => state.user.filters;

function* loadFromApi() {
  try {
    const page = yield select(getPage);
    const itemsPerPage = yield select(getItemsPerPage);
    const orderBy = yield select(getOrderBy);
    const orderByDirection = yield select(getOrderByDirection);
    const filters = yield select(getFilters);

    const params = {
      query: {
        $select: ['id'],
        $or: [
          {
            firstname: {
              $iLike: `%${filters.search}%`,
            },
          },
          {
            lastname: {
              $iLike: `%${filters.search}%`,
            },
          },
          {
            email: {
              $iLike: `%${filters.search}%`,
            },
          },
        ],
        $limit: itemsPerPage,
        $skip: (page - 1) * itemsPerPage,
        $sort: {
          [orderBy]: orderByDirection,
        },
      },
    };

    const data = yield call(() => api.service(COLLECTION_NAME).find(params));

    yield put(actions.loadFromApiSuccess(data.data, data.total));
  } catch (error) {
    console.log(error);
    yield put(actions.loadFromApiError(error));
  }
}

function* removeFromApi({ payload }) {
  try {
    const data = yield call(() =>
      api.service(COLLECTION_NAME).remove(payload.id)
    );

    yield put(actions.removeFromApiSuccess(data));
  } catch (error) {
    console.log('REMOVE CALL ERROR');

    console.log(error);
    yield put(actions.removeFromApiError(error));
  }
}

function* saveToApi({ payload }) {
  const { item } = payload;

  try {
    const params = {
      firstname: item.firstname,
      lastname: item.lastname,
      email: item.email,
      avatarId: item.avatarId,
      age: item.age,
      phone: item.phone,
      city: item.city,
      timezone: item.timezone,
      timezoneId: item.timezoneId,
      preferedDays: item.preferedDays,
			isEmailConfirmed: item.isEmailConfirmed
    };

    let itemResponse = {};

    if (item.id) {
      itemResponse = yield call(() =>
        api.service(COLLECTION_NAME).patch(item.id, params)
      );
    } else {
      itemResponse = yield call(() =>
        api.service(COLLECTION_NAME).create(params)
      );
    }

    yield put(actions.saveToApiSuccess(itemResponse));
    yield put(updateAuthUser(itemResponse));
    yield put(profileActions.saveToApiSuccess(itemResponse));
  } catch (error) {
    console.log(error);
    yield put(actions.saveToApiError(error));
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_FROM_API, loadFromApi),
    takeEvery(actions.SET_PAGE, loadFromApi),
    takeEvery(actions.SET_ITEMS_PER_PAGE, loadFromApi),
    takeEvery(actions.SET_ORDER_BY, loadFromApi),
    takeEvery(actions.SET_ORDER_BY_DIRECTION, loadFromApi),
    takeEvery(actions.SET_FILTER, loadFromApi),
    takeEvery(actions.SAVE_TO_API, saveToApi),
    takeEvery(actions.REMOVE_FROM_API, removeFromApi),
  ]);
}
