import api from 'api';

// eslint-disable-next-line import/no-cycle
import store from 'redux/store';

import actions from './actions';

import _ from 'lodash'

api.service('lesson').on('patched', async (lesson) => {
  const path = _.split(window.location.pathname, '/').slice(1)

  const storeData = store.getState()
  const userRole = _.get(storeData, 'authUser.user.role')

  if (
    userRole === 'student' &&
    path[0] === 'lesson' &&
    path[1] === lesson.id
  ) {
    store.dispatch(actions.saveToApiSuccess(lesson));
  }
});

api.service('test-result').on('created', async (result) => {

  const resultId = result.id;

  const storeData = store.getState()
  const testId = _.get(storeData, 'lessonStudentPage.studentTest.id')

  if (resultId === testId) {
    store.dispatch(actions.setTestStep('postview'))
  }

});

export default api;