import { all, takeEvery, put, call, select } from "redux-saga/effects";
import _ from "lodash";
import nodemailer from "nodemailer";
import api from "./realtime";
import actions from "./actions";

import {
  lessonInclude,
  streamLessonsInclude,
  homeworkInclude,
	mediaInclude,
} from "./includes";
import axios from "axios";

const getUser = (state) => state.authUser.user;
const getLessonId = (state) => state.lessonStudentPage.lesson.id;
const getExerciseId = (state) => state.lessonStudentPage.lesson.exerciseId;
const getIsIndividual = (state) => state.lessonStudentPage.lesson.isIndividual;

function* loadFromApi({ payload }) {
  try {
    const { lessonId } = payload;

    const user = yield select(getUser);

    const lesson = yield call(() =>
      api.service("lesson").findOne({
        query: {
          id: lessonId,
          include: lessonInclude,
					$getIndividual: true
        },
      })
    );

		if (!lesson.isIndividual && lesson.exercise) {
			lesson.exercise.exerciseMedias = (yield call(() => api.service("exercise-media").find({
				query: {
					exerciseId: lesson.exerciseId,
					include: mediaInclude
				}
			}))).data;	
		}

    const userSubscription = !lesson.isIndividual ? (yield call(() =>
      api
        .service("user-subscription")
        .find({
          query: {
            userId: user.id,
            $select: ["id", "subscriptionId"],
            include: [
              {
                model: "subscription",
                as: "subscription",
								where: {
									streamId: lesson.streamId
								},
                required: true,
                attributes: ["id", "exerciseCount", "isFull", "streamId"],
              },
            ],
          },
        }).then((res) => {
					return (
						_.find(res.data, (userSubscription) => _.get(userSubscription, "subscription.isFull")) ||
						_.maxBy(res.data, "subscription.exerciseCount")
					);
				})
    )) : 1;

    const streamLessons = !lesson.isIndividual ? (yield call(() =>
      api
        .service("lesson")
        .find({
          query: {
            streamId: lesson.streamId,
            $select: ["id", "startAt"],
            $sort: {
              startAt: 1,
            },
            include: streamLessonsInclude,
						$getIndividual: true
          },
        })
        .then((res) => res.data)
    )) : [];

		const buyedExercisesCount = _.get(userSubscription, "subscription.exerciseCount");

    const currentLessonCount =
      _.findIndex(streamLessons, (lesson) => lesson.id === lessonId) + 1;

    const hasAccessToLesson =
		 buyedExercisesCount >= currentLessonCount ||
		 _.get(userSubscription, "subscription.isFull") ||
		 (lesson.isIndividual && lesson.userId === user.id);

    const isLastBuyedLesson =
      buyedExercisesCount === currentLessonCount &&
      currentLessonCount < streamLessons.length;

    yield put(
      actions.loadFromApiSuccess(
        lesson,
        streamLessons,
        hasAccessToLesson,
        isLastBuyedLesson
      )
    );
  } catch (error) {
    console.log(error);
    yield put(actions.loadFromApiError(error));
  }
}

function* loadTestFromApi() {
  try {
    const exerciseId = yield select(getExerciseId);
    const lessonId = yield select(getLessonId);
		const isIndividual = yield select(getIsIndividual);
    const user = yield select(getUser);

    const test = yield call(() =>
      api.service("exercise-media").findOne({
        query: {
          exerciseId,
          type: "test",
        },
      })
    );

    if(!test) {
      yield put(actions.loadTestFromApiSuccess(null));
      return;
    }

		if (isIndividual && !test) {
			yield put(actions.loadTestFromApiSuccess({}));
		}

    test.url = `${process.env.REACT_APP_API_HOST}/quiz/${test.mediaId}?USER_ID=${user.id}&LESSON_ID=${lessonId}&EXERCISE_MEDIA_ID=${test.id}`;

    yield put(actions.loadTestFromApiSuccess(test));
  } catch (error) {
    console.log(error);
    yield put(actions.loadFromApiError(error));
  }
}

function* loadHomeworkFromApi() {
  try {
    const lessonId = yield select(getLessonId);
    const user = yield select(getUser);

    const homework = yield call(() =>
      api.service("student-homework").findOne({
        query: {
          $or: {
						lessonId,
						lessonIndividualId: lessonId,
					},
          studentId: user.id,
          include: homeworkInclude,
        },
      })
    );

    yield put(actions.loadHomeworkFromApiSuccess(homework));
  } catch (error) {
    console.log(error);
    yield put(actions.loadFromApiError(error));
  }
}

function* loadStudentTestFromApi() {
  try {
    const lessonId = yield select(getLessonId);
    const user = yield select(getUser);

    const test = yield call(() =>
      api.service("student-test").findOne({
        query: {
          $or: {
						lessonId,
						lessonIndividualId: lessonId,
					},
          studentId: user.id,
        },
      })
    );

    if (test) {
      test.result = JSON.parse(test.result).quizReport;
      yield put(actions.setTestStep("postview"));
    } else {
			yield put(actions.setTestStep("preview"));
		};

    yield put(actions.loadStudentTestFromApiSuccess(test));
  } catch (error) {
    console.log(error);
    yield put(actions.loadFromApiError(error));
  }
}

function* createHomework({ payload }) {
  try {
    const { params, files } = payload;
    const createFiles = files.filter((item) => !item.id)
    const token = localStorage.getItem('auth')

    let homework = {id: params.homeworkId}

    if(!params.homeworkId){
      homework = yield call(() =>
        api.service("student-homework").create(params)
      );
    }

    const createdMedias = yield all(
      _.map(createFiles, (file) =>
        call(async () =>{ 
          const formData = new FormData()
          formData.append('id', file.upload.uuid)
          formData.append('originalname', file.name)
          formData.append('mimetype', file.type)
          formData.append('uri', file)
          const { data } = await axios({
            method: 'POST',
            url: `${process.env.REACT_APP_API_HOST}/upload`,
            data: formData,
            headers: { Authorization: `Bearer ${token}` }
          })
          return data[0];
        })
      )
    );

    yield all(
      _.map(createdMedias, (media) =>
        call(() =>
          api.service("student-homework-media").create({
            studentHomeworkId: homework.id,
            mediaId: media.id,
          })
        )
      )
    );

    const createdHomework = yield call(() =>
      api.service("student-homework").findOne({
        query: {
          id: homework.id,
          include: homeworkInclude,
        },
      })
    );

    yield put(actions.createHomeworkSuccess(createdHomework));
  } catch (error) {
    console.log(error);
    yield put(actions.saveToApiError(error));
  }
}

function* saveToApi({ payload }) {
  try {
    yield put(actions.saveToApiSuccess());
  } catch (error) {
    console.log(error);
    yield put(actions.saveToApi(error));
  }
}

function* saveUserToApi({ payload }) {
  try {
    const { userId, points } = payload;
		const lessonId = yield select(getLessonId);

		const oldUser = yield call(() => api.service('user').findOne({
			query: {
				id: userId
			}
		}));

    const user = yield call(() => api.service("user").patch(userId, {
			...points, points: points.points + oldUser.points
		}));

		if (user) {
			const studentTest = yield call(() => api.service("student-test").findOne({
				query: {
					$select: ['id'],
					lessonId,
					studentId: user.id
				}
			}));

			yield call(() => api.service("student-test").patch(studentTest.id, {
				isStudentEvaluate: true
			}));
		}
		
    yield put(actions.saveUserToApiSuccess(user));
  } catch (error) {
    console.log(error);
    yield put(actions.saveToApi(error));
  }
}

function* setSubjectPoints({ payload }) {
  try {
    const { subjectId, points } = payload;

    const user = yield select(getUser);

    const userData = yield call(() => api.service("user").get(user.id));

    const subjectPoints = _.get(userData, `pointsInSubjects[${subjectId}]`);

    api.service("user").patch(user.id, {
      pointsInSubjects: {
        ...userData.pointsInSubjects,
        [subjectId]: subjectPoints ? subjectPoints + points : points,
      },
    });
  } catch (error) {
    console.log(error);
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_FROM_API, loadFromApi),

    takeEvery(actions.LOAD_TEST_FROM_API, loadTestFromApi),
    takeEvery(actions.LOAD_HOMEWORK_FROM_API, loadHomeworkFromApi),
    takeEvery(actions.CREATE_HOMEWORK, createHomework),

    takeEvery(actions.LOAD_STUDENT_TEST_FROM_API, loadStudentTestFromApi),

    takeEvery(actions.SAVE_TO_API, saveToApi),
    takeEvery(actions.SAVE_USER_TO_API, saveUserToApi),
    takeEvery(actions.SET_SUBJECT_POINTS, setSubjectPoints),
  ]);
}
