import moment from "moment";

export const studentStreamsInclude = (userId) => {
  return [
    {
      model: "stream",
      as: "stream",
      attributes: ["id", "num", "name"],
      include: [
        {
          model: "course",
          as: "course",
          attributes: ["id", "name"],
          include: [
            {
              model: "subject",
              as: "subject",
              attributes: ["id", "name"],
            },
          ],
        },
        {
          model: "lesson",
          as: "lessons",
          required: false,
          attributes: [
            "id",
            "startAt",
            "finishAt",
            "isHidden",
            "isIndividual",
            "teacherId",
            "streamId",
          ],
          where: {
            deleted: false,
          },
					separate: true,
          order: [["startAt", "ASC"]],
          include: [
            {
              model: "exercise",
              as: "exercise",
              required: false,
              where: {
                deleted: false,
              },
              attributes: ["name", "duration", "hasTest", "hasHomework"],
            },
            {
              model: "visit",
              as: "visits",
              where: {
                userId,
              },
              limit: 1,
              attributes: ["id", "createdAt"],
            },
            {
              model: "student_homework",
              as: "studentHomeworks",
              limit: 1,
              where: {
                studentId: userId,
              },
            },
            {
              model: "student_test",
              as: "studentTests",
              where: {
                studentId: userId,
              },
              limit: 1,
              attributes: [
                "id",
                "submitTime",
                "score",
                "tasksCount",
                "taskCompleted",
                "teacherComment",
              ],
            },
          ],
        },
      ],
    },
  ];
};

export const teacherStreamsInclude = (userId) => {
  return [
    {
      model: "stream",
      as: "stream",
      attributes: ["id", "num", "name"],
      include: [
        {
          model: "course",
          as: "course",
          attributes: ["name"],
          where: {
            deleted: false,
          },
          include: [
            {
              model: "subject",
              as: "subject",
              attributes: ["id", "name"],
              where: {
                deleted: false,
              },
            },
          ],
        },
        {
          model: "lesson",
          as: "lessons",
          required: false,
          attributes: [
            "id",
            "startAt",
            "finishAt",
            "isHidden",
            "isIndividual",
            "teacherId",
          ],
          where: {
            deleted: false,
          },
					separate: true,
          order: [["startAt", "ASC"]],
          include: [
            {
              model: "exercise",
              as: "exercise",
              required: false,
              where: {
                deleted: false,
              },
              attributes: ["name", "duration"],
            },
            {
              model: "visit",
              as: "visits",
              where: {
                userId,
              },
              limit: 1,
							separate: true,
              order: [["createdAt", "DESC"]],
              attributes: ["id", "createdAt"],
            },
            {
              model: "student_homework",
              as: "studentHomeworks",
              limit: 100,
              where: {
                submitTime: {
                  gt: moment().subtract(360, "hours").format(),
                  lt: moment().format(),
                },
              },
							separate: true,
              order: [["submitTime", "DESC"]],
              attributes: [
                "id",
                "studentId",
                "submitTime",
                "score",
                "teacherComment",
              ],
              include: [
                {
                  model: "user",
                  as: "student",
                  attributes: ["firstname", "lastname"],
                },
              ],
            },
            {
              model: "student_test",
              as: "studentTests",
              limit: 100,
              attributes: [
                "id",
                "submitTime",
                "score",
                "tasksCount",
                "taskCompleted",
                "teacherComment",
              ],
            },
          ],
        },
      ],
    },
  ];
};

export const nextLessonsInclude = () => {
  return [
    {
      model: "exercise",
      as: "exercise",
      required: true,
      where: {
        deleted: false,
      },
      attributes: ["name", "duration"],
    },
    {
      model: "stream",
      as: "stream",
      required: true,
      where: {
        deleted: false,
      },
      attributes: ["num", "name"],
      include: [
        {
          model: "course",
          as: "course",
          required: true,
          where: {
            deleted: false,
          },
          attributes: ["name"],
          include: [
            {
              model: "subject",
              as: "subject",
              required: true,
              where: {
                deleted: false,
              },
              attributes: ["name"],
            },
          ],
        },
      ],
    },
  ];
};

export const homeworksInclude = () => {
  return [
    {
      model: "user",
      as: "student",
      attributes: ["firstname", "lastname"],
    },
		{
			model: "lesson_individual",
			as: "individualLesson",
			attributes: ["id", "startAt", "isIndividual"],
			include: [
				{
          model: "exercise",
          as: "exercise",
          required: false,
          where: {
            deleted: false,
          },
          attributes: ["name", "duration"],
        },
			]
		},
    {
      model: "lesson",
      as: "lesson",
      attributes: ["id", "startAt"],
      include: [
        {
          model: "exercise",
          as: "exercise",
          required: true,
          where: {
            deleted: false,
          },
          attributes: ["name", "duration"],
        },
        {
          model: "stream",
          as: "stream",
          required: true,
          where: {
            deleted: false,
          },
          attributes: ["num", "name"],
          include: [
            {
              model: "course",
              as: "course",
              required: true,
              where: {
                deleted: false,
              },
              attributes: ["name"],
              include: [
                {
                  model: "subject",
                  as: "subject",
                  required: true,
                  where: {
                    deleted: false,
                  },
                  attributes: ["name"],
                },
              ],
            },
          ],
        },
      ],
    },
  ];
};

export const ownTeacherLessonsInclude = () => {
  return [
		{
      model: "exercise",
      as: "exercise",
      required: true,
      where: {
        deleted: false,
      },
      attributes: ["name", "duration"],
    },
		{
			model: 'stream',
			as: 'stream',
			required: true,
			attributes: ['id', 'num', "name"],
			where: {
				deleted: false,
			},
			include: [
				{
					model: 'course',
					as: 'course',
					required: true,
					attributes: ['id', 'name'],
					where: {
						deleted: false,
					},
					include: [
						{
							model: 'subject',
							as: 'subject',
							required: true,
							attributes: ['id', 'name'],
							where: {
								deleted: false,
							},
						}
					]
				},
			]
		}
	]
};