import actions from "./actions";

const initState = {
  isLoading: false,
  error: null,
  user: {},
  userSubscription: [],
  individualLessons: [],
  nextLessons: [],
	ownTeacherLessons: [],
  homeworks: [],
  homeworksTotalItems: null,
  streamUsers: [],
};

export default function reducer(state = initState, { type, payload }) {
  switch (type) {
    case actions.LOAD_FROM_API:
      return {
        ...state,
        isLoadingUser: true,
        error: false,
      };
    case actions.LOAD_FROM_API_SUCCESS:
      return {
        ...state,
        isLoadingUser: false,
        user: payload.user,
        error: false,
      };
    case actions.LOAD_STREAMS_FROM_API:
      return {
        ...state,
        error: false,
      };
    case actions.LOAD_STREAMS_FROM_API_SUCCESS:
      return {
        ...state,
        streamUsers: payload.streamUsers,
        streamTotalItems: payload.streamTotalItems,
        error: false,
      };
    case actions.CLEAR_STREAMS_STATE:
      return {
        ...state,
        streamUsers: initState.streamUsers,
        streamTotalItems: initState.streamTotalItems,
        error: initState.error,
      };
    case actions.LOAD_NEXT_LESSONS_FROM_API:
      return {
        ...state,
        error: false,
      };
    case actions.LOAD_NEXT_LESSONS_FROM_API_SUCCESS:
      return {
        ...state,
        nextLessons: payload.nextLessons,
        error: false,
      };
    case actions.LOAD_HOMEWORKS_FROM_API:
      return {
        ...state,
        error: false,
      };
    case actions.LOAD_HOMEWORKS_FROM_API_SUCCESS:
      return {
        ...state,
        homeworks: payload.nextHomeworks,
        homeworksTotalItems: payload.totalItems,
        error: false,
      };
    case actions.LOAD_USER_FROM_API:
      return {
        ...state,
        error: false,
      };
    case actions.LOAD_USER_FROM_API_SUCCESS:
      return {
        ...state,
        isLoading: false,
        user: payload.user,
        error: false,
      };
    case actions.LOAD_INDIVIDUAL_LESSON_FROM_API:
      return {
        ...state,
        error: false,
      };
    case actions.LOAD_INDIVIDUAL_LESSON_FROM_API_SUCCESS:
      return {
        ...state,
        individualLessons: payload.individualLessons,
        error: false,
      };
    case actions.LOAD_USER_SUBSCRIPTION_FROM_API:
      return {
        ...state,
        error: false,
      };
    case actions.LOAD_USER_SUBSCRIPTION_FROM_API_SUCCESS:
      return {
        ...state,
        isLoading: false,
        userSubscription: payload.userSubscription,
        error: false,
      };
    case actions.LOAD_FROM_API_ERROR:
      return {
        ...state,
        isLoading: false,
        error: payload.error,
      };
    case actions.SAVE_TO_API:
      return {
        ...state,
        isLoading: true,
        error: false,
      };
    case actions.SAVE_TO_API_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          ...payload.user,
        },
        isLoading: false,
        error: false,
      };
    case actions.SAVE_TO_API_ERROR:
      return {
        ...state,
        isLoading: false,
        error: payload.error,
      };
		case actions.LOAD_OWN_TEACHER_LESSONS:
			return {
				...state,
				error: false
			};
		case actions.LOAD_OWN_TEACHER_LESSONS_SUCCESS:
			return {
				...state,
				error: false,
				ownTeacherLessons: payload.lessons
			};
		case actions.LOAD_OWN_TEACHER_LESSONS_ERROR:
			return {
				...state,
				error: payload.error
			};
    default:
      return state;
  }
}
