const DOCUMENT = "LESSON_STUDENT_PAGE_";

const actions = {
  LOAD_FROM_API: `${DOCUMENT}LOAD_FROM_API`,
  LOAD_FROM_API_SUCCESS: `${DOCUMENT}LOAD_FROM_API_SUCCESS`,
  LOAD_FROM_API_ERROR: `${DOCUMENT}LOAD_FROM_API_ERROR`,

  LOAD_TEST_FROM_API: `${DOCUMENT}LOAD_TEST_FROM_API`,
  LOAD_TEST_FROM_API_SUCCESS: `${DOCUMENT}LOAD_TEST_FROM_API_SUCCESS`,

  LOAD_HOMEWORK_FROM_API: `${DOCUMENT}LOAD_HOMEWORK_FROM_API`,
  LOAD_HOMEWORK_FROM_API_SUCCESS: `${DOCUMENT}LOAD_HOMEWORK_FROM_API_SUCCESS`,

  LOAD_STUDENT_TEST_FROM_API: `${DOCUMENT}LOAD_STUDENT_TEST_FROM_API`,
  LOAD_STUDENT_TEST_FROM_API_SUCCESS: `${DOCUMENT}LOAD_STUDENT_TEST_FROM_API_SUCCESS`,

  LOAD_STUDENT_HOMEWORK_FROM_API: `${DOCUMENT}LOAD_STUDENT_HOMEWORK_FROM_API`,
  LOAD_STUDENT_HOMEWORK_FROM_API_SUCCESS: `${DOCUMENT}LOAD_STUDENT_HOMEWORK_FROM_API_SUCCESS`,

  SAVE_TO_API: `${DOCUMENT}SAVE_TO_API`,
  SAVE_TO_API_SUCCESS: `${DOCUMENT}SAVE_TO_API_SUCCESS`,
  SAVE_TO_API_ERROR: `${DOCUMENT}SAVE_TO_API_ERROR`,

  SAVE_USER_TO_API: `${DOCUMENT}SAVE_USER_TO_API`,
  SAVE_USER_TO_API_SUCCESS: `${DOCUMENT}SAVE_USER_TO_API_SUCCESS`,

  SAVE_HOMEWORK_TO_API: `${DOCUMENT}SAVE_HOMEWORK_TO_API`,
  SAVE_HOMEWORK_TO_API_SUCCESS: `${DOCUMENT}SAVE_HOMEWORK_TO_API_SUCCESS`,

  CREATE_HOMEWORK: `${DOCUMENT}CREATE_HOMEWORK`,
  CREATE_HOMEWORK_SUCCESS: `${DOCUMENT}CREATE_HOMEWORK_SUCCESS`,

  SET_INVITE_URL: `${DOCUMENT}SET_INVITE_URL`,

  SET_SUBJECT_POINTS: `${DOCUMENT}SET_SUBJECT_POINTS`,
  SET_TEST_STEP: `${DOCUMENT}SET_TEST_STEP`,

  loadFromApi: (lessonId) => {
    return {
      type: actions.LOAD_FROM_API,
      payload: {
        lessonId,
      },
    };
  },

  loadFromApiSuccess: (
    lesson,
    streamLessons,
    hasAccessToLesson,
    isLastBuyedLesson
  ) => ({
    type: actions.LOAD_FROM_API_SUCCESS,
    payload: {
      lesson,
      streamLessons,
      hasAccessToLesson,
      isLastBuyedLesson,
    },
  }),

  loadFromApiError: (error) => ({
    type: actions.LOAD_FROM_API_ERROR,
    payload: {
      error,
    },
  }),

  loadStudentTestFromApi: () => {
    return {
      type: actions.LOAD_STUDENT_TEST_FROM_API,
      payload: {},
    };
  },

  loadStudentTestFromApiSuccess: (studentTest) => ({
    type: actions.LOAD_STUDENT_TEST_FROM_API_SUCCESS,
    payload: {
      studentTest,
    },
  }),

  loadTestFromApi: () => {
    return {
      type: actions.LOAD_TEST_FROM_API,
      payload: {},
    };
  },

  loadTestFromApiSuccess: (test) => ({
    type: actions.LOAD_TEST_FROM_API_SUCCESS,
    payload: {
      test,
    },
  }),

  loadHomeworkFromApi: () => {
    return {
      type: actions.LOAD_HOMEWORK_FROM_API,
      payload: {},
    };
  },

  loadHomeworkFromApiSuccess: (homework) => ({
    type: actions.LOAD_HOMEWORK_FROM_API_SUCCESS,
    payload: {
      homework,
    },
  }),

  saveHomeworkToApi: (homeworkId, params) => {
    return {
      type: actions.SAVE_HOMEWORK_TO_API,
      payload: {
        homeworkId,
        params,
      },
    };
  },

  createHomework: (params, files) => ({
    type: actions.CREATE_HOMEWORK,
    payload: {
      params,
      files,
    },
  }),

  createHomeworkSuccess: (homework) => {
    return {
      type: actions.CREATE_HOMEWORK_SUCCESS,
      payload: {
        homework,
      },
    };
  },

  saveHomeworkToApiSuccess: (homework) => ({
    type: actions.SAVE_HOMEWORK_TO_API_SUCCESS,
    payload: {
      homework,
    },
  }),

  saveToApi: (lesson) => ({
    type: actions.SAVE_TO_API,
    payload: {
      lesson,
    },
  }),
  saveToApiSuccess: (lesson) => ({
    type: actions.SAVE_TO_API_SUCCESS,
    payload: {
      lesson,
    },
  }),
  saveToApiError: (error) => ({
    type: actions.SAVE_TO_API_ERROR,
    payload: {
      error,
    },
  }),

  saveUserToApi: (userId, points) => {
    return {
      type: actions.SAVE_USER_TO_API,
      payload: {
        userId,
        points,
      },
    };
  },

  saveUserToApiSuccess: (user) => {
    return {
      type: actions.SAVE_USER_TO_API_SUCCESS,
      payload: {
        user,
      },
    };
  },

  setInviteUrl: (url) => ({
    type: actions.SET_INVITE_URL,
    payload: { url },
  }),

  setTestStep: (testStep) => ({
    type: actions.SET_TEST_STEP,
    payload: { testStep },
  }),

  setSubjectPoints: (subjectId, points) => ({
    type: actions.SET_SUBJECT_POINTS,
    payload: { subjectId, points },
  }),
};

export default actions;
