import io from 'socket.io-client';
import feathers from '@feathersjs/feathers';
import socketio from '@feathersjs/socketio-client';
import auth from '@feathersjs/authentication-client';
import findOne from 'feathers-findone';

const socket = io(process.env.REACT_APP_API_HOST);
const app = feathers();

// Configure feathers-client
// Setup the transport (Rest, Socket, etc.) here
app.configure(socketio(socket));

app.service('upload').timeout = 500000;
// Available options are listed in the "Options" section
app.configure(
  auth({
    storageKey: 'auth',
  })
);

app.configure(findOne());

export default app;
