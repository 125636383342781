import { all, call, fork, put, takeEvery, select } from 'redux-saga/effects';

import _ from 'lodash';

import app from 'api';
import nodemailer from 'nodemailer';

import {
  LOGIN_USER,
  REGISTER_USER,
  LOGOUT_USER,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
} from '../root-actions';

import {
  loginUser,
  loginUserSuccess,
  loginUserError,
  registerUserSuccess,
  registerUserError,
  forgotPasswordSuccess,
  forgotPasswordError,
  resetPasswordSuccess,
  resetPasswordError,
} from './actions';
import api from 'redux/userVault/realtime';

const getReferrerId = (state) => state.authUser.referrerId;

const COLLECTION_USER_NAME = 'user';
const COLLECTION_PREFERED_DAY_NAME = 'prefered-day';

const loginWithEmailPasswordAsync = async (email, password) =>
  app
    .authenticate({
      strategy: 'local',
      email,
      password,
    })
    .then((authUser) => authUser)
    .catch((error) => error);

function* loginWithEmailPassword({ payload }) {
  const { email, password } = payload.user;
  const { history } = payload;
  try {
    const loginUser = yield call(loginWithEmailPasswordAsync, email, password);

    if (!loginUser.message) {
      localStorage.setItem('userId', loginUser.user.id);
      yield put(loginUserSuccess(loginUser.user));
      history.push('/');
    } else {
      yield put(loginUserError(loginUser.message));
    }
  } catch (error) {
    yield put(loginUserError(error));
  }
}

export function* watchLoginUser() {
  yield takeEvery(LOGIN_USER, loginWithEmailPassword);
}

const registerWithEmailPasswordAsync = async (email, password) =>
  app
    .createUserWithEmailAndPassword(email, password)
    .then((authUser) => authUser)
    .catch((error) => error);

function* registerWithEmailPassword({ payload }) {
  const {
    email,
    password,
    firstname,
    lastname,
    age,
    city,
    phone,
    preferedDays,
  } = payload.user;
  let { history, refId } = payload;
  let role = 'student';

  const paramsArr = _.get(history, 'location.search').substring(1).split(/=|&/);

  paramsArr.forEach((item, i) => {
    if (item === 'ref') {
      refId = paramsArr[i + 1];
    }
    if (item === 'isTeacher' && paramsArr[i + 1] === 'true') {
      role = 'teacher';
    }
  }, []);

  try {
    const dataUser = {
      email,
      password,
      firstname,
      lastname,
      role,
    };

    if (age) dataUser.age = age;
    if (phone) dataUser.phone = phone;
    if (city) dataUser.city = city;

    delete dataUser.terms;
    delete dataUser.advertising;

    if (preferedDays && !_.isEmpty(preferedDays))
      dataUser.preferedDays = preferedDays;

    dataUser.referrerId = yield select(getReferrerId);

    const createUser = yield call(() =>
      app.service(COLLECTION_USER_NAME).create(dataUser)
    );

    if (!_.isNull(createUser)) {
      // здесь добавляем юзеру очки

      if (refId && refId !== '-') {
        const refUserPoints = yield call(() =>
          app
            .service('user')
            .get(refId)
            .then((res) => res?.points)
        );

        const pointsAddition = yield call(() =>
          app.service('user').patch(refId, { points: refUserPoints + 10 })
        );
      }

      const verifyLink = yield call(() =>
        app
          .service('access-link')
          .get(createUser.email)
          .then((res) => res.url)
      );

      if (!verifyLink) throw Error('Ошибка регистрации');

      yield call(() =>
        api.service('mailer').create({
          to: createUser.email,
          template: 'success-registration',
          email: createUser.email,
          registrationLink: verifyLink,
        })
      );

      yield put(registerUserSuccess({ user: createUser }));
      const loginUser = yield call(loginWithEmailPasswordAsync, email, password);
  
      if (!loginUser.message) {
        localStorage.setItem('userId', loginUser.user.id);
        yield put(loginUserSuccess(loginUser.user));
      } else {
        yield put(loginUserError(loginUser.message));
      }
    }
  } catch (error) {
    console.log(error);
    yield put(registerUserError(error));
  }
}

export function* watchRegisterUser() {
  yield takeEvery(REGISTER_USER, registerWithEmailPassword);
}

const logoutAsync = async (history) => {
  await app
    .logout()
    .then((authUser) => authUser)
    .catch((error) => error);
  history.push('/');
};

function* logout({ payload }) {
  const { history } = payload;
  try {
    yield call(logoutAsync, history);
    localStorage.removeItem('userId');
    localStorage.removeItem('auth');
  } catch (error) {
    console.error(error);
  }
}

export function* watchLogoutUser() {
  yield takeEvery(LOGOUT_USER, logout);
}

const forgotPasswordAsync = async (email) => {
  return app
    .sendPasswordResetEmail(email)
    .then((user) => user)
    .catch((error) => error);
};

function* forgotPassword({ payload }) {
  const { email } = payload.forgotUserMail;
  try {
    const forgotPasswordStatus = yield call(forgotPasswordAsync, email);
    if (!forgotPasswordStatus) {
      yield put(forgotPasswordSuccess('success'));
    } else {
      yield put(forgotPasswordError(forgotPasswordStatus.message));
    }
  } catch (error) {
    yield put(forgotPasswordError(error));
  }
}

export function* watchForgotPassword() {
  yield takeEvery(FORGOT_PASSWORD, forgotPassword);
}

const resetPasswordAsync = async (resetPasswordCode, newPassword) => {
  return app
    .confirmPasswordReset(resetPasswordCode, newPassword)
    .then((user) => user)
    .catch((error) => error);
};

function* resetPassword({ payload }) {
  const { newPassword, resetPasswordCode } = payload;
  try {
    const resetPasswordStatus = yield call(
      resetPasswordAsync,
      resetPasswordCode,
      newPassword
    );
    if (!resetPasswordStatus) {
      yield put(resetPasswordSuccess('success'));
    } else {
      yield put(resetPasswordError(resetPasswordStatus.message));
    }
  } catch (error) {
    yield put(resetPasswordError(error));
  }
}

export function* watchResetPassword() {
  yield takeEvery(RESET_PASSWORD, resetPassword);
}

export default function* rootSaga() {
  yield all([
    fork(watchLoginUser),
    fork(watchLogoutUser),
    fork(watchRegisterUser),
    fork(watchForgotPassword),
    fork(watchResetPassword),
  ]);
}
