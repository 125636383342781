import { all } from 'redux-saga/effects';
import authSagas from './auth/saga';
import todoSagas from './todo/saga';
import chatSagas from './chat/saga';
import surveyListSagas from './surveyList/saga';
import surveyDetailSagas from './surveyDetail/saga';
import courseSaga from './course/saga';
import courseVaultSaga from './courseVault/saga';
import mediaVaultSaga from './mediaVault/saga';
import userSaga from './user/saga';
import userVaultSaga from './userVault/saga';
import userRatingSaga from './userRating/saga';
import userRatingVaultSaga from './userRatingVault/saga';
import operationSaga from './operation/saga';
import operationVaultSaga from './operationVault/saga';
import profileSaga from './profile/saga';
import lessonChatSaga from './lessonChat/saga';
import lessonStudentPageSaga from './lessonStudentPage/saga';
import lessonTeacherPageSaga from './lessonTeacherPage/saga';

export default function* rootSaga(getState) {
  yield all([
    authSagas(),
    todoSagas(),
    chatSagas(),
    surveyListSagas(),
    surveyDetailSagas(),
    courseSaga(),
    courseVaultSaga(),
    mediaVaultSaga(),
    userSaga(),
    userVaultSaga(),
    userRatingSaga(),
    userRatingVaultSaga(),
    operationSaga(),
    operationVaultSaga(),
    profileSaga(),
    lessonChatSaga(),
    lessonStudentPageSaga(),
    lessonTeacherPageSaga(),
  ]);
}
