import actions from './actions';

const initState = {
  isLoading: false,
  isLoadingTest: false,
  isLoadingStudentTest: false,
  isLoadingHomework: false,
  isSavingHomework: false,
  hasAccessToLesson: false,
  isLastBuyedLesson: false,
  error: null,
  lesson: {},
  streamLessons: [],
  homework: null,
  test: null,
  studentTest: null,
  points: 0,
  testStep: null,
};

export default function reducer(state = initState, { type, payload }) {
  switch (type) {
    case actions.LOAD_FROM_API:
      return {
        ...state,
        isLoading: true,
        error: false,
      };
    case actions.LOAD_FROM_API_SUCCESS:
      return {
        ...state,
        isLoading: false,
        lesson: payload.lesson,
        streamLessons: payload.streamLessons,
        hasAccessToLesson: payload.hasAccessToLesson,
        isLastBuyedLesson: payload.isLastBuyedLesson,
        error: false,
      };
    case actions.LOAD_FROM_API_ERROR:
      return {
        ...state,
        isLoading: false,
        error: payload.error,
      };
    case actions.LOAD_TEST_FROM_API:
      return {
        ...state,
        isLoadingTest: true,
        error: false,
      };
    case actions.LOAD_TEST_FROM_API_SUCCESS:
      return {
        ...state,
        isLoadingTest: false,
        test: payload.test,
        error: false,
      };
    case actions.LOAD_HOMEWORK_FROM_API:
      return {
        ...state,
        isLoadingHomework: true,
        error: false,
      };
    case actions.LOAD_HOMEWORK_FROM_API_SUCCESS:
      return {
        ...state,
        isLoadingHomework: false,
        homework: payload.homework || {},
        error: false,
      };
    case actions.LOAD_STUDENT_TEST_FROM_API:
      return {
        ...state,
        isLoadingStudentTest: true,
        error: false,
      };
    case actions.LOAD_STUDENT_TEST_FROM_API_SUCCESS:
      return {
        ...state,
        isLoadingStudentTest: false,
        studentTest: payload.studentTest,
        error: false,
      };
    case actions.SAVE_TO_API:
      return {
        ...state,
        isLoading: true,
        error: false,
      };
    case actions.SAVE_TO_API_SUCCESS:
      return {
        ...state,
        error: false,
        lesson: {
          ...state.lesson,
          ...payload.lesson,
        },
      };
    case actions.SAVE_USER_TO_API:
      return {
        ...state,
        error: false,
      };
    case actions.SAVE_USER_TO_API_SUCCESS:
      return {
        ...state,
        points: payload.user.points,
      };
    case actions.SAVE_HOMEWORK_TO_API:
      return {
        ...state,
        isSavingHomework: true,
        error: false,
      };
    case actions.SAVE_HOMEWORK_TO_API_SUCCESS:
      return {
        ...state,
        error: false,
        isSavingHomework: false,
        homework: {
          ...state.homework,
          ...payload.homework,
        },
      };
    case actions.CREATE_HOMEWORK:
      return {
        ...state,
        isSavingHomework: true,
        error: false,
      };
    case actions.CREATE_HOMEWORK_SUCCESS:
      return {
        ...state,
        error: false,
        isSavingHomework: false,
        homework: {
          ...state.homework,
          ...payload.homework,
        },
      };
    case actions.SAVE_TO_API_ERROR:
      return {
        ...state,
        isLoading: false,
        error: payload.error,
      };
    case actions.SET_TEST_STEP:
      return {
        ...state,
        testStep: payload.testStep,
      };
    case actions.SET_SUBJECT_POINTS:
      return {
        ...state,
      };
    default:
      return state;
  }
}
