const DOCUMENT = "PROFILE_";

const actions = {
  LOAD_FROM_API: `${DOCUMENT}LOAD_FROM_API`,
  LOAD_FROM_API_SUCCESS: `${DOCUMENT}LOAD_FROM_API_SUCCESS`,
  LOAD_FROM_API_ERROR: `${DOCUMENT}LOAD_FROM_API_ERROR`,

  LOAD_STREAMS_FROM_API: `${DOCUMENT}LOAD_STREAMS_FROM_API`,
  LOAD_STREAMS_FROM_API_SUCCESS: `${DOCUMENT}LOAD_STREAMS_FROM_API_SUCCESS`,
  CLEAR_STREAMS_STATE: `${DOCUMENT}CLEAR_STREAMS_STATE`,

  LOAD_INDIVIDUAL_LESSON_FROM_API: `${DOCUMENT}LOAD_INDIVIDUAL_LESSON_FROM_API`,
  LOAD_INDIVIDUAL_LESSON_FROM_API_SUCCESS: `${DOCUMENT}LOAD_INDIVIDUAL_LESSON_FROM_API_SUCCESS`,

  LOAD_NEXT_LESSONS_FROM_API: `${DOCUMENT}LOAD_NEXT_LESSONS_FROM_API`,
  LOAD_NEXT_LESSONS_FROM_API_SUCCESS: `${DOCUMENT}LOAD_NEXT_LESSONS_FROM_API_SUCCESS`,

  LOAD_HOMEWORKS_FROM_API: `${DOCUMENT}LOAD_NEXT_HOMEWORKS_FROM_API`,
  LOAD_HOMEWORKS_FROM_API_SUCCESS: `${DOCUMENT}LOAD_NEXT_HOMEWORKS_FROM_API_SUCCESS`,

  LOAD_USER_FROM_API: `${DOCUMENT}LOAD_USER_FROM_API`,
  LOAD_USER_FROM_API_SUCCESS: `${DOCUMENT}LOAD_USER_FROM_API_SUCCESS`,

  LOAD_USER_SUBSCRIPTION_FROM_API: `${DOCUMENT}LOAD_USER_SUBSCRIPTION_FROM_API`,
  LOAD_USER_SUBSCRIPTION_FROM_API_SUCCESS: `${DOCUMENT}LOAD_USER_SUBSCRIPTION_FROM_API_SUCCESS`,

  LOAD_SUBSCRIPTION_FROM_API: `${DOCUMENT}LOAD_SUBSCRIPTION_FROM_API`,
  LOAD_SUBSCRIPTION_FROM_API_SUCCESS: `${DOCUMENT}LOAD_SUBSCRIPTION_FROM_API_SUCCESS`,

  SAVE_TO_API: `${DOCUMENT}SAVE_TO_API`,
  SAVE_TO_API_SUCCESS: `${DOCUMENT}SAVE_TO_API_SUCCESS`,
  SAVE_TO_API_ERROR: `${DOCUMENT}SAVE_TO_API_ERROR`,

  SET_PREFERDAYS: `${DOCUMENT}SET_PREFERDAYS`,

	LOAD_OWN_TEACHER_LESSONS: `${DOCUMENT}LOAD_OWN_TEACHER_LESSONS`,
	LOAD_OWN_TEACHER_LESSONS_SUCCESS: `${DOCUMENT}LOAD_OWN_TEACHER_LESSONS_SUCCESS`,
	LOAD_OWN_TEACHER_LESSONS_ERROR: `${DOCUMENT}LOAD_OWN_TEACHER_LESSONS_ERROR`,

  loadFromApi: (startDate) => {
    return {
      type: actions.LOAD_FROM_API,
      payload: {
        startDate,
      },
    };
  },

  loadFromApiSuccess: (user) => ({
    type: actions.LOAD_FROM_API_SUCCESS,
    payload: {
      user,
    },
  }),

  clearStreamsState: () => ({
    type: actions.CLEAR_STREAMS_STATE,
  }),

  loadStreamsFromApi: () => {
    return {
      type: actions.LOAD_STREAMS_FROM_API,
    };
  },

  loadStreamsFromApiSuccess: (streamUsers, streamTotalItems) => ({
    type: actions.LOAD_STREAMS_FROM_API_SUCCESS,
    payload: {
      streamUsers,
      streamTotalItems,
    },
  }),

  loadNextLessonsFromApi: (id, startDate) => ({
    type: actions.LOAD_NEXT_LESSONS_FROM_API,
    payload: {
      userId: id,
      startDate,
    },
  }),

  loadNextLessonsFromApiSuccess: (nextLessons) => ({
    type: actions.LOAD_NEXT_LESSONS_FROM_API_SUCCESS,
    payload: {
      nextLessons,
    },
  }),

  loadHomeworksFromApi: (userId) => ({
    type: actions.LOAD_HOMEWORKS_FROM_API,
    payload: {
      userId,
    },
  }),

  loadHomeworksFromApiSuccess: (nextHomeworks, totalItems) => ({
    type: actions.LOAD_HOMEWORKS_FROM_API_SUCCESS,
    payload: {
      nextHomeworks,
      totalItems,
    },
  }),

  loadUserFromApi: () => {
    return {
      type: actions.LOAD_USER_FROM_API,
    };
  },

  loadUserFromApiSuccess: (user) => ({
    type: actions.LOAD_USER_FROM_API_SUCCESS,
    payload: {
      user,
    },
  }),

  loadIndividualLessonFromApi: () => ({
    type: actions.LOAD_INDIVIDUAL_LESSON_FROM_API,
  }),

  loadIndividualLessonFromApiSuccess: (individualLessons) => ({
    type: actions.LOAD_INDIVIDUAL_LESSON_FROM_API_SUCCESS,
    payload: {
      individualLessons,
    },
  }),

  loadUserSubscriptionFromApi: () => {
    return {
      type: actions.LOAD_USER_SUBSCRIPTION_FROM_API,
    };
  },

  loadUserSubscriptionFromApiSuccess: (userSubscription) => ({
    type: actions.LOAD_USER_SUBSCRIPTION_FROM_API_SUCCESS,
    payload: {
      userSubscription,
    },
  }),

  loadFromApiError: (error) => ({
    type: actions.LOAD_FROM_API_ERROR,
    payload: {
      error,
    },
  }),

  saveToApi: (userId, params) => {
    return {
      type: actions.SAVE_TO_API,
      payload: {
        userId,
        params,
      },
    };
  },

  saveToApiSuccess: (user) => ({
    type: actions.SAVE_TO_API_SUCCESS,
    payload: {
      user,
    },
  }),

  saveToApiError: (error) => ({
    type: actions.SAVE_TO_API_ERROR,
    payload: {
      error,
    },
  }),

	loadOwnTeacherLessons: (startDate) => ({
		type: actions.LOAD_OWN_TEACHER_LESSONS,
    payload: {
      startDate,
    }
	}),

	loadOwnTeacherLessonsSuccess: (lessons) => ({
		type: actions.LOAD_OWN_TEACHER_LESSONS_SUCCESS,
		payload: {
			lessons,
		}
	}),

	loadOwnTeacherLessonsError: (error) => ({
    type: actions.LOAD_OWN_TEACHER_LESSONS_ERROR,
    payload: {
      error,
    },
  }),
};

export default actions;
