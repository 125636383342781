import { all, takeEvery, put, call, select } from 'redux-saga/effects';
import api from 'api';
import actions from './actions';

import _ from 'lodash';

const COLLECTION_NAME = 'operation'; // change your collection

const getUser = (state) => state.authUser.user;
const getPage = (state) => state.operation.page;
const getItemsPerPage = (state) => state.operation.itemsPerPage;

function* loadFromApi() {
  try {
    const user = yield select(getUser);
    const page = yield select(getPage);
    const itemsPerPage = yield select(getItemsPerPage);

    const params = {
      query: {
        userId: user.id,
        $select: ['id'],
        $limit: itemsPerPage,
        $skip: (page - 1) * itemsPerPage,
        $sort: {
          time: -1,
        },
      },
    };

    const data = yield call(() => api.service(COLLECTION_NAME).find(params));

    yield put(actions.loadFromApiSuccess(data.data, data.total));
  } catch (error) {
    console.log(error);
    yield put(actions.loadFromApiError(error));
  }
}

function* createBalanceOperation({ payload }) {
  try {
    const user = yield select(getUser);

    const { amount, type } = payload;
    const data = {
      amount,
      description: 'Пополнение счёта',
      typeId: type,
      userId: user.id,
    };

    const transaction = yield call(() =>
      api.service('transactions').create(data)
    );

    window.location.href = transaction.robokassaUrl;
  } catch (error) {
    console.log(error);
    yield put(actions.loadFromApiError(error));
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_FROM_API, loadFromApi),
    takeEvery(actions.SET_PAGE, loadFromApi),
    takeEvery(actions.CREATE_BALANCE_OPERATION, createBalanceOperation),
    takeEvery(actions.SET_ITEMS_PER_PAGE, loadFromApi),
  ]);
}
