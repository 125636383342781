import { all, takeEvery, put, call, select } from 'redux-saga/effects';
import api from 'api';
import profileActions from 'redux/profile/actions';
import actions from './actions';
import { updateUser as updateAuthUser } from '../auth/actions';
import _ from 'lodash';

const COLLECTION_NAME = 'user'; // change your collection

const getPage = (state) => state.userRating.page;
const getItemsPerPage = (state) => state.userRating.itemsPerPage;
const getOrderBy = (state) => state.userRating.orderBy;
const getOrderByDirection = (state) => state.userRating.orderByDirection;
const getFilters = (state) => state.userRating.filters;
const getUsers = (state) => state.userRating.users;
const getIsInfinityScroll = (state) => state.userRating.isInfinityScroll;
const getOwner = (state) => state.profile.user;

function* loadFromApi() {
  try {
    const page = yield select(getPage);
    const itemsPerPage = yield select(getItemsPerPage);
    const orderBy = yield select(getOrderBy);
    const orderByDirection = yield select(getOrderByDirection);
    const filters = yield select(getFilters);
    const users = yield select(getUsers);
    const isInfinityScroll = yield select(getIsInfinityScroll);

    const params = {
      query: {
        $select: ['id'],
        $limit: itemsPerPage,
        $skip: (page - 1) * itemsPerPage,
        $sort: {
          [orderBy]: orderByDirection,
          key: 1,
        },
        role: 'student',
      },
    };

    if (filters.subjectId && orderBy === 'points') {
      params.query['$sort'] = {
        [`pointsInSubjects.${filters.subjectId}`]: 1,
        firstname: 1,
        key: 1,
      };
    }

    const response = yield call(() =>
      api.service(COLLECTION_NAME).find(params)
    );

    if (isInfinityScroll) {
      yield put(
        actions.loadFromApiSuccess([...users, ...response.data], response.total)
      );
    } else {
      yield put(actions.loadFromApiSuccess(response.data, response.total));
    }
  } catch (error) {
    console.log(error);
    yield put(actions.loadFromApiError(error));
  }
}

function* removeFromApi({ payload }) {
  try {
    const data = yield call(() =>
      api.service(COLLECTION_NAME).remove(payload.id)
    );

    yield put(actions.removeFromApiSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(actions.removeFromApiError(error));
  }
}

function* getOwnerPage(params) {
  try {
    const owner = yield select(getOwner);
    const orderByDirection = yield select(getOrderByDirection);
    const orderBy = yield select(getOrderBy);
    const itemsPerPage = yield select(getItemsPerPage);
    const filters = yield select(getFilters);

    //сначала нужно получить количество юзеров до авториз. юзера

    const queryMore = {
      $limit: 0,
      $sort: {
        [orderBy]: orderByDirection,
        key: 1,
      },
    };

    if (orderBy === 'points') {
      queryMore.points = {
        $gt: owner.points,
      };
    }

    if (orderBy === 'firstname') {
      queryMore.firstname = {
        $gt: owner.firstname,
      };
    }

    const usersTotalMore = yield call(() =>
      api
        .service(COLLECTION_NAME)
        .find({
          query: queryMore,
        })
        .then((res) => res.total)
    );

    // нужно получить количество юзеров с таким же значением orderBy(но по ключу key)

    const queryEqual = {
      $limit: 0,
      $sort: {
        [orderBy]: orderByDirection,
        key: 1,
      },
    };

    if (orderBy === 'points') {
      queryEqual.points = owner.points;
      queryEqual.key = {
        $lt: owner.key,
      };
    }

    if (orderBy === 'firstname') {
      queryEqual.firstname = owner.firstname;
    }

    const usersTotalEqual = yield call(() =>
      api
        .service(COLLECTION_NAME)
        .find({
          query: queryEqual,
        })
        .then((res) => res.total)
    );

    const ownerPosition = usersTotalEqual + usersTotalMore + 1;

    const ownerPage = Math.ceil(ownerPosition / itemsPerPage);

    yield put(actions.setPage(ownerPage));
  } catch (error) {
    console.log(error);
  }
}

function* saveToApi({ payload }) {
  const { item } = payload;

  try {
    const params = {
      firstname: item.firstname,
      lastname: item.lastname,
      email: item.email,
      avatarId: item.avatarId,
      age: item.age,
      phone: item.phone,
      city: item.city,
      timezone: item.timezone,
      timezoneId: item.timezoneId,
      preferedDays: item.preferedDays,
    };

    let itemResponse = {};

    if (item.id) {
      itemResponse = yield call(() =>
        api.service(COLLECTION_NAME).patch(item.id, params)
      );
    } else {
      itemResponse = yield call(() =>
        api.service(COLLECTION_NAME).create(params)
      );
    }

    yield put(actions.saveToApiSuccess(itemResponse));
    yield put(updateAuthUser(itemResponse));
    yield put(profileActions.saveToApiSuccess(itemResponse));
  } catch (error) {
    console.log(error);
    yield put(actions.saveToApiError(error));
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_FROM_API, loadFromApi),
    takeEvery(actions.SET_PAGE, loadFromApi),
    takeEvery(actions.SET_OWNER_PAGE, getOwnerPage),
    takeEvery(actions.SET_ITEMS_PER_PAGE, loadFromApi),
    takeEvery(actions.SET_INFINITY_SCROLL, loadFromApi),
    takeEvery(actions.SET_ORDER_BY, loadFromApi),
    takeEvery(actions.SET_ORDER_BY_DIRECTION, loadFromApi),
    takeEvery(actions.SET_FILTER, loadFromApi),
    takeEvery(actions.SAVE_TO_API, saveToApi),
    takeEvery(actions.REMOVE_FROM_API, removeFromApi),
  ]);
}
