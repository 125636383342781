import api from 'api';

// eslint-disable-next-line import/no-cycle
import store from 'redux/store';

import actions from './actions';

api.service('course').on('created', (item) => {
  store.dispatch(actions.getItem(item.id));
});

api.service('course').on('patched', (item) => {
  store.dispatch(actions.getItem(item.id));
});

api.service('stream').on('patched', (item) => {
  if (item.courseId) {
    store.dispatch(actions.getItem(item.courseId));
  }
});

api.service('course').on('updated', (item) => {
  store.dispatch(actions.getItem(item.id));
});

api.service('course').on('removed', (item) => {
  store.dispatch(
    actions.getItemSuccess({ ...item, courseMedias: [], deleted: true })
  );
});

export default api;
