const DOCUMENT = 'OPERATION_';

const actions = {
  LOAD_FROM_API: `${DOCUMENT}LOAD_FROM_API`,
  LOAD_FROM_API_SUCCESS: `${DOCUMENT}LOAD_FROM_API_SUCCESS`,
  LOAD_FROM_API_ERROR: `${DOCUMENT}LOAD_FROM_API_ERROR`,

  CREATE_BALANCE_OPERATION: `${DOCUMENT}CREATE_BALANCE_OPERATION`,

  SET_PAGE: `${DOCUMENT}SET_PAGE`,
  SET_ITEMS_PER_PAGE: `${DOCUMENT}SET_ITEMS_PER_PAGE`,

  loadFromApi: () => {
    return {
      type: actions.LOAD_FROM_API,
    };
  },

  loadFromApiSuccess: (data, totalItems) => ({
    type: actions.LOAD_FROM_API_SUCCESS,
    payload: {
      data,
      totalItems,
    },
  }),

  loadFromApiError: (error) => ({
    type: actions.LOAD_FROM_API_ERROR,
    payload: {
      error,
    },
  }),

  createBalanceOperation: (amount, type) => ({
    type: actions.CREATE_BALANCE_OPERATION,
    payload: {
      amount,
      type
    }
  }),

  setPage: (page) => ({
    type: actions.SET_PAGE,
    payload: {
      page,
    },
  }),

  setItemsPerPage: (itemsPerPage) => ({
    type: actions.SET_ITEMS_PER_PAGE,
    payload: {
      itemsPerPage,
    },
  }),

};

export default actions;
