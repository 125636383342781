import { all, takeEvery, put, call, select } from 'redux-saga/effects';
import api from 'api';
import actions from './actions';
import _ from 'lodash';
import streamsInclude from './includes';
import moment from 'helpers/moment';

const COLLECTION_NAME = 'course'; // change your collection

const getUserId = (state) => state.authUser.user.id;
const getOrderBy = (state) => state.course.orderBy;
const getOrderByDirection = (state) => state.course.orderByDirection;
const getFilters = (state) => state.course.filters;

function* loadFromApi() {
  try {
    const orderBy = yield select(getOrderBy);
    const orderByDirection = yield select(getOrderByDirection);
    const filters = yield select(getFilters);

    const coursesQuery = {
      $select: ['id', 'subjectId', 'name'],
      $or: [
        {
          name: {
            $iLike: `%${filters.search}%`,
          },
        },
        {
          description: {
            $iLike: `%${filters.search}%`,
          },
        },
      ],
      $sort: {
        [orderBy]: orderByDirection,
      },
    };

    const subjectParams = {
      $select: ['id', 'name'],
      $or: [
        {
          name: {
            $iLike: `%${filters.search}%`,
          },
        },
        {
          description: {
            $iLike: `%${filters.search}%`,
          },
        },
      ],
    };

    const lessonParams = {
      query: {
        $select: ['id', 'name', 'courseId'],
        $or: [
          {
            name: {
              $iLike: `%${filters.search}%`,
            },
          },
          {
            description: {
              $iLike: `%${filters.search}%`,
            },
          },
        ],
        $limit: 35,
      },
    };

    const totalCoursesCount = (yield call(() =>
      api.service(COLLECTION_NAME).find({
        query: {
          ...coursesQuery,
          $limit: 0,
        },
      })
    )).total;

    const data = yield call(() =>
      api.service(COLLECTION_NAME).find({
        query: {
          ...coursesQuery,
          $limit: totalCoursesCount,
        },
      })
    );

    const totalSubjectsCount = (yield call(() =>
      api.service('subject').find({
        query: {
          ...subjectParams,
          $limit: 0,
        },
      })
    )).total;

    const subjects = yield call(() =>
      api.service('subject').find({
        query: {
          ...subjectParams,
          $limit: totalSubjectsCount,
        },
      })
    );

    const lesson = yield call(() => api.service('exercise').find(lessonParams));

    yield put(
      actions.loadFromApiSuccess(
        data.data,
        subjects.data,
        lesson.data,
        data.total
      )
    );
  } catch (error) {
    console.log(error);
    yield put(actions.loadFromApiError(error));
  }
}

function* loadNextStreams({ payload }) {
  try {
    const nextStreamsIds = yield call(() =>
      api
        .service('stream')
        .find({
          query: {
            courseId: payload.id,
            isClosed: false,
            isCancelled: false,
            $limit: 50,
            $sort: {
              num: 1,
            },
            include: [
              {
                model: 'lesson',
                as: 'lessons',
                required: false,
                attributes: ['id', 'startAt'],
                where: {
                  deleted: false,
                  isIndividual: false,
                  startAt: {
                    gte: moment().format(),
                  },
                },
              },
            ],
          },
        })
        .then((res) => _.map(res.data, (stream) => stream.id))
    );

    const streams = yield call(() =>
      api.service('stream').find({
        query: {
          id: {
            $in: nextStreamsIds,
          },
          courseId: payload.id,
          isClosed: false,
          $limit: 50,
          $sort: {
            num: 1,
          },
          include: streamsInclude(nextStreamsIds),
        },
      })
    );

    const subscriptions = yield call(() =>
      api.service('subscription').find({
        query: {
          $limit: 50,
          streamId: {
            $in: nextStreamsIds,
          },
          isActive: true,
          $sort: {
            isIndividual: -1,
            isFree: -1,
            exerciseCount: 1,
            isFull: 1,
          },
        },
      })
    );

    const exercises = yield call(() =>
      api.service('exercise').find({
        query: {
          courseId: payload.id,
          $limit: 0,
          $select: ['id'],
          deleted: false,
        },
      })
    );

    yield put(
      actions.loadNextStreamsSuccess(
        streams.data,
        subscriptions.data,
        exercises.total
      )
    );
  } catch (error) {
    console.log(error);
  }
}

function* removeFromApi({ payload }) {
  try {
    const data = yield call(() =>
      api.service(COLLECTION_NAME).remove(payload.id)
    );

    yield put(actions.removeFromApiSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(actions.removeFromApiError(error));
  }
}

function* saveToApi({ payload }) {
  const { item } = payload;

  try {
    const params = {
      name: item.name,
      description: item.description,
      imageId: item.imageId,
    };

    let itemResponse = {};

    if (item.id) {
      itemResponse = yield call(() =>
        api.service(COLLECTION_NAME).patch(item.id, params)
      );
    } else {
      itemResponse = yield call(() =>
        api.service(COLLECTION_NAME).create(params)
      );
    }

    yield put(actions.saveToApiSuccess(itemResponse));
  } catch (error) {
    console.log(error);
    yield put(actions.saveToApiError(error));
  }
}

function* buyStreamSubscription({ payload }) {
  try {
    const userId = yield select(getUserId);

    const { subscriptionId, cost } = payload;

    const operation = yield call(() =>
      api.service('operation').create({
        typeId: 'OUT_PAY',
        userId,
        time: moment().format(),
        amount: cost,
      })
    );

    yield call(() =>
      api.service('user-subscription').create({
        userId,
        subscriptionId,
        operationId: operation.id,
      })
    );

    const streamId = yield call(() =>
      api
        .service('subscription')
        .get(subscriptionId)
        .then((res) => res.streamId)
    );

    const existingStreamUser = yield call(() =>
      api.service('stream-user').findOne({
        query: {
          userId,
          streamId,
        },
      })
    );

    if (_.isEmpty(existingStreamUser)) {
      yield call(() =>
        api.service('stream-user').create({
          userId,
          streamId,
        })
      );
    }
  } catch (error) {
    console.log(error);
    yield put(actions.saveToApiError(error));
  }
}

function* buyIndividualLesson({ payload }) {
  try {
    const userId = yield select(getUserId);

    const { subscriptionId, streamId, cost, data } = payload;

    console.log('payload', payload);
    const operation = yield call(() =>
      api.service('operation').create({
        typeId: 'OUT_PAY',
        userId,
        time: moment().format(),
        amount: cost,
      })
    );

    const individualLesson = yield call(() =>
      api.service('lesson-individual').create({
        isActive: true,
        isIndividual: true,
        extra: data,
      })
    );

    yield call(() =>
      api.service('user-subscription').create({
        individualLessonId: individualLesson.id,
        userId,
        subscriptionId,
        operationId: operation.id,
      })
    );
  } catch (error) {
    console.log('errHere');
    console.log(error);
    yield put(actions.saveToApiError(error));
  }
}

function* bookingStream({ payload }) {
  try {
    const userId = yield select(getUserId);

    const { streamId, cost } = payload;

    const operation = yield call(() =>
      api.service('operation').create({
        typeId: 'OUT_PAY',
        userId,
        time: moment().format(),
        amount: cost,
      })
    );

    yield call(() =>
      api.service('stream-booking').create({
        userId,
        streamId,
        operationId: operation.id,
      })
    );
  } catch (error) {
    console.log(error);
    yield put(actions.saveToApiError(error));
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_FROM_API, loadFromApi),
    takeEvery(actions.LOAD_NEXT_STREAMS, loadNextStreams),
    takeEvery(actions.SET_PAGE, loadFromApi),
    takeEvery(actions.SET_ITEMS_PER_PAGE, loadFromApi),
    takeEvery(actions.SET_ORDER_BY, loadFromApi),
    takeEvery(actions.SET_ORDER_BY_DIRECTION, loadFromApi),
    takeEvery(actions.SET_FILTER, loadFromApi),
    takeEvery(actions.SAVE_TO_API, saveToApi),
    takeEvery(actions.REMOVE_FROM_API, removeFromApi),
    takeEvery(actions.BUY_INDIVIDUAL_LESSON, buyIndividualLesson),
    takeEvery(actions.BUY_STREAM_SUBSCRIPTION, buyStreamSubscription),
    takeEvery(actions.BOOKED_STREAM, bookingStream),
  ]);
}
