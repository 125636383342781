import { all, takeEvery, put, call, select } from 'redux-saga/effects';
import api from 'api';
import actions from './actions';
import _ from 'lodash';
import moment from 'moment';

import {
  studentStreamsInclude,
  nextLessonsInclude,
  homeworksInclude,
  ownTeacherLessonsInclude,
} from './includes';

const getUserId = (state) => state.authUser.userId;
const getUserRole = (state) => state.authUser.user.role;
const isFree = true;

function* loadFromApi({ payload }) {
  try {
    const { startDate } = payload;

    const firstDayOfCalendar = moment(
      moment(startDate).startOf('month')
    ).startOf('week')._d;
    const lastDayOfCalendar = moment(moment(startDate).endOf('month')).endOf(
      'week'
    )._d;

    const userId = yield select(getUserId);
    const userRole = yield select(getUserRole);

    const user = yield call(() => api.service('user').get(userId));

    const userSubscription = yield call(() =>
      api
        .service('user-subscription')
        .find({
          query: {
            $startDate: firstDayOfCalendar,
            $endDate: lastDayOfCalendar,
            userId,
            include: [
              {
                model: 'subscription',
                as: 'subscription',
								include: studentStreamsInclude(userId)
              },
            ],
						$limit: 50,
            $sort: {
              createdAt: -1
            }
          },
        })
        .then((res) => res.data)
    );

    yield put(actions.loadUserSubscriptionFromApiSuccess(userSubscription));
    yield put(actions.loadNextLessonsFromApi(user.id, startDate));
    yield put(actions.loadStreamsFromApi());
    yield put(actions.loadFromApiSuccess(user));
  } catch (error) {
    console.log(error);
    yield put(actions.loadFromApiError(error));
  }
}

function* loadNextLessonsFromApi({ payload }) {
  try {
    const userRole = yield select(getUserRole);

    const { userId, startDate } = payload;

    const streamTotalItems = yield call(() =>
      api
        .service('stream-user')
        .find({
          query: {
            userId,
          },
        })
        .then((res) => res.total)
    );

    const streamUsers = yield call(() =>
      api.service('stream-user').find({
        query: {
          userId,
          $select: ['streamId', 'userId'],
          $limit: streamTotalItems,
        },
      })
    );

    const streamIds = _.map(streamUsers.data, (value) => value.streamId);

    const nextLessonsParams = {
      query: {
        $or: {
          streamId: {
            $in: streamIds,
          },
          teacherId: userId,
        },
        isHidden: false,
        startAt: {
          $gt: moment(),
        },
        $limit: 3,
        $sort: {
          startAt: 1,
        },
        include: nextLessonsInclude(),
      },
    };

    const nextLessons = yield call(() =>
      api.service('lesson').find(nextLessonsParams)
    );

    if (userRole === 'teacher') {
      yield put(actions.loadHomeworksFromApi(userId));
      yield put(actions.loadOwnTeacherLessons(startDate));
    }

    yield put(actions.loadNextLessonsFromApiSuccess(nextLessons.data));
  } catch (error) {
    console.log(error);
    yield put(actions.loadFromApiError(error));
  }
}

function* loadHomeworksFromApi({ payload }) {
  try {
    const { userId } = payload;

    const teacherLessonsTotalItems = (yield call(() =>
      api.service('lesson').find({
        query: {
          teacherId: userId,
          startAt: {
            $gt: moment().subtract(370, 'hours').format(),
            $lt: moment().format(),
          },
          $limit: 0,
        },
      })
    )).total;

    const teacherLessons = (yield call(() =>
      api.service('lesson').find({
        query: {
          $select: ['id', 'teacherId', 'startAt'],
          teacherId: userId,
          startAt: {
            $gt: moment().subtract(370, 'hours').format(),
            $lt: moment().format(),
          },
          $limit: teacherLessonsTotalItems,
        },
      })
    )).data;

    const teacherLessonsIds = teacherLessons.map(
      (teacherLesson) => teacherLesson.id
    );

    const teacherIndividualLessonsTotalItems = (yield call(() =>
      api.service('lesson-individual').find({
        query: {
          teacherId: userId,
          startAt: {
            $gt: moment().subtract(370, 'hours').format(),
            $lt: moment().format(),
          },
          $limit: 0,
        },
      })
    )).total;

    const teacherIndividualLessons = (yield call(() =>
      api.service('lesson-individual').find({
        query: {
          $select: ['id', 'teacherId', 'startAt'],
          teacherId: userId,
          startAt: {
            $gt: moment().subtract(370, 'hours').format(),
            $lt: moment().format(),
          },
          $limit: teacherIndividualLessonsTotalItems,
        },
      })
    )).data;

    const teacherIndividualLessonsIds = teacherIndividualLessons.map(
      (teacherIndividualLesson) => teacherIndividualLesson.id
    );

    const homeworks = yield call(() =>
      api.service('student-homework').find({
        query: {
          $or: {
            lessonId: {
              $in: teacherLessonsIds,
            },
            lessonIndividualId: {
              $in: teacherIndividualLessonsIds,
            },
          },
          verifiedAt: null,
          submitTime: {
            $gt: moment().subtract(360, 'hours').format(),
            $lt: moment().format(),
          },
          $sort: {
            submitTime: 1,
          },
          $limit: 100,
          include: homeworksInclude(),
        },
      })
    );

    yield put(
      actions.loadHomeworksFromApiSuccess(homeworks.data, homeworks.total)
    );
  } catch (error) {
    console.log(error);
    yield put(actions.loadFromApiError(error));
  }
}

function* loadStreamsFromApi() {
  try {
    const userId = yield select(getUserId);
    const userRole = yield select(getUserRole);

    const streamTotalItems = yield call(() =>
      api
        .service('stream-user')
        .find({
          query: {
            userId,
            $limit: 0,
            include: studentStreamsInclude(userId),
          },
        })
        .then((res) => res.total)
    );

    const streamUsers = yield call(() =>
      api.service('stream-user').find({
        query: {
          userId,
          $select: ['id', 'streamId', 'userId'],
          $limit: streamTotalItems,
          include: studentStreamsInclude(userId),
        },
      })
    );

    const individualLessonsCount = yield call(() =>
      userRole === 'teacher'
        ? api
            .service('lesson-individual')
            .find({
              query: {
                $select: ['id'],
                $limit: 0,
                teacherId: {
                  $in: [userId],
                },
              },
            })
            .then((result) => result.data)
        : api
            .service('lesson-individual')
            .find({
              query: {
                $select: ['id'],
                $limit: 0,
                userId: {
                  $in: [userId],
                },
              },
            })
            .then((result) => result.total)
    );

    const individualLesson = yield call(() =>
      userRole === 'teacher'
        ? api
            .service('lesson-individual')
            .find({
              query: {
                $select: [
                  'id',
                  'userId',
                  'teacherId',
                  'courseId',
                  'subjectId',
                  'startAt',
                  'finishAt',
                  'isIndividual',
                  'isHidden',
                ],
                teacherId: {
                  $in: [userId],
                },
                $limit: individualLessonsCount,
              },
            })
            .then((result) => result.data)
        : api
            .service('lesson-individual')
            .find({
              query: {
                $select: [
                  'id',
                  'userId',
                  'teacherId',
                  'courseId',
                  'subjectId',
                  'startAt',
                  'finishAt',
                  'isIndividual',
                  'isHidden',
                ],
                userId: {
                  $in: [userId],
                },
                $limit: individualLessonsCount,
              },
            })
            .then((result) => result.data)
    );

    yield put(actions.loadIndividualLessonFromApiSuccess(individualLesson));

    yield put(
      actions.loadStreamsFromApiSuccess(streamUsers.data, streamUsers.total)
    );
  } catch (error) {
    console.log(error);
    yield put(actions.loadFromApiError(error));
  }
}

function* loadUserFromApi() {
  try {
    const userId = yield select(getUserId);

    const user = yield call(() => api.service('user').get(userId));

    yield put(actions.loadUserFromApiSuccess(user));
  } catch (error) {
    console.log(error);
    yield put(actions.loadFromApiError(error));
  }
}

function* saveToApi({ payload }) {
  try {
    const { userId, params } = payload;

    const user = yield call(() => api.service('user').patch(userId, params));

    yield put(actions.saveToApiSuccess(user));
  } catch (error) {
    console.log(error);
    yield put(actions.saveToApiError(error));
  }
}

function* loadOwnTeacherLessons({ payload }) {
  try {
    const { startDate } = payload;
    const userId = yield select(getUserId);

    const firstDayOfCalendar = moment(
      moment(startDate).startOf('month')
    ).startOf('week')._d;
    const lastDayOfCalendar = moment(moment(startDate).endOf('month')).endOf(
      'week'
    )._d;

    const params = {
      query: {
        teacherId: userId,
        isHidden: false,
        startAt: {
          $gt: firstDayOfCalendar,
          $lt: lastDayOfCalendar,
        },
        include: ownTeacherLessonsInclude(),
        $limit: 50,
      },
    };

    let lessonsTotalItems = (yield call(() =>
      api.service('lesson').find(params)
    )).total;

    let lessonsData = [];

    let skip = 0;
    while (lessonsTotalItems > 0) {
      const lessons = yield call(() =>
        api.service('lesson').find({
          query: {
            teacherId: userId,
            isHidden: false,
            startAt: {
              $gt: firstDayOfCalendar,
              $lt: lastDayOfCalendar,
            },
            include: ownTeacherLessonsInclude(),
            $skip: skip,
            $limit: 50,
          },
        })
      );
      lessonsData = [...lessonsData, ...lessons.data];
      lessonsTotalItems -= 50;
      skip += 50;
    }

    yield put(actions.loadOwnTeacherLessonsSuccess(lessonsData));
  } catch (error) {
    console.log(error);
    yield put(actions.loadOwnTeacherLessonsError(error));
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_FROM_API, loadFromApi),
    takeEvery(actions.LOAD_NEXT_LESSONS_FROM_API, loadNextLessonsFromApi),
    takeEvery(actions.LOAD_HOMEWORKS_FROM_API, loadHomeworksFromApi),
    takeEvery(actions.LOAD_STREAMS_FROM_API, loadStreamsFromApi),
    takeEvery(actions.LOAD_USER_FROM_API, loadUserFromApi),
    takeEvery(actions.SAVE_TO_API, saveToApi),
    takeEvery(actions.LOAD_OWN_TEACHER_LESSONS, loadOwnTeacherLessons),
  ]);
}
