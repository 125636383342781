import { all, takeEvery, put, call, select } from 'redux-saga/effects';
import { NotificationManager } from 'components/common/react-notifications';
import api from './realtime';
import actions from './actions';
import _ from 'lodash';

const COLLECTION_NAME = 'user'; // change your collection

const getFilters = (state) => state.userRating.filters;

function* getItem({ payload }) {
  try {
    const filters = yield select(getFilters);

    const user = yield call(() => api.service(COLLECTION_NAME).get(payload.id));

    const usersTotalMoreParams = {
      query: {
        $limit: 0,
      },
    };

    if (filters.subjectId) {
      usersTotalMoreParams.query[`pointsInSubjects.${filters.subjectId}`] = {
        $gt: _.get(user, `pointsInSubjects[${filters.subjectId}]`) || 0,
      };
    } else {
      usersTotalMoreParams.query.points = {
        $gt: user.points,
      };
    }

    const usersTotalMore = yield call(() =>
      api
        .service(COLLECTION_NAME)
        .find(usersTotalMoreParams)
        .then((res) => res.total)
    );

    const usersTotalEqualParams = {
      query: {
        $limit: 0,
      },
    };

    if (filters.subjectId) {
      usersTotalEqualParams.query[
        `pointsInSubjects.${filters.subjectId}`
      ] = _.get(user, `pointsInSubjects[${filters.subjectId}]`);
      usersTotalEqualParams.query.firstname = {
        $lt: user.firstname,
      };
    } else {
      usersTotalEqualParams.query.points = user.points;
      usersTotalEqualParams.query.key = {
        $lt: user.key,
      };
    }

    const usersTotalEqual = yield call(() =>
      api
        .service(COLLECTION_NAME)
        .find(usersTotalEqualParams)
        .then((res) => res.total)
    );

    user.place = usersTotalMore + usersTotalEqual + 1;

    yield put(actions.getItemSuccess(user));
  } catch (error) {
    NotificationManager.error(
      error.message,
      error.name,
      10000,
      null,
      null,
      'filled'
    );
    console.log(error);

    yield put(actions.getItemError());
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actions.GET_ITEM, getItem)]);
}
