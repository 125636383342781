import moment from 'moment';

const streamsInclude = () => {
  return [
    {
      model: 'lesson',
      as: 'lessons',
      required: false,
      attributes: ['id', 'startAt'],
      where: {
        deleted: false,
        isIndividual: false,
      },
			separate: true,
      order: [['startAt', 'ASC']],
      include: [
        {
          model: 'exercise',
          as: 'exercise',
          required: false,
          where: {
            deleted: false,
          },
          attributes: ['name', 'duration'],
        },
      ],
    },
  ];
};

export default streamsInclude;
