import { all, takeEvery, put, call } from 'redux-saga/effects';
import { NotificationManager } from 'components/common/react-notifications';
import api from './realtime';
import actions from './actions';
import _ from 'lodash';

const COLLECTION_NAME = 'course'; // change your collection

function* getItem({ payload }) {
  try {
    const data = yield call(() => api.service(COLLECTION_NAME).get(payload.id));

    data.nextStream.forEach((item, index) => {
      data.nextStream[index].lessons.sort(function(a,b){
        return new Date(a.startAt) - new Date(b.startAt);
      });
    })

    yield put(actions.getItemSuccess(data));
  } catch (error) {
    yield put(actions.getItemError());
    console.log(error);
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actions.GET_ITEM, getItem)]);
}
