export const lessonInclude = [
  {
    model: 'exercise',
    as: 'exercise',
    attributes: [
      'id',
      'name',
      'description',
      'content',
      'hasTest',
      'hasHomework',
      'homework',
			'courseId'
    ],
    include: [
      {
        model: 'course',
        as: 'course',
        attributes: ['id', 'name'],
      }
    ]
  },
  {
    model: 'stream',
    as: 'stream',
    attributes: [
      'id',
      'num',
    ]
  }
]

export const homeworkInclude = [
  {
    model: 'student_homework_media',
    where: {
      deleted: false
    },
    as: 'medias',
    include: [
      {
        model: 'media',
        as: 'media'
      }
    ]
  }
]

export const streamLessonsInclude = [
  {
    model: 'exercise',
    as: 'exercise',
    required: true,
    where: {
      deleted: false
    },
    attributes: ['id', 'name'],
    include: [
      {
        model: 'course',
        as: 'course',
        attributes: ['id', 'name'],
        where: {
          deleted: false
        },
      }
    ]
  },
]

export const mediaInclude = [
	{
		model: 'media',
		as: 'media'
	}
]