export const lessonInclude = [
  {
    model: 'exercise',
    as: 'exercise',
    attributes: [
      'id',
      'name',
      'description',
      'content',
      'hasTest',
      'hasHomework',
      'homework',
      'isInviteAllowed',
      'courseId',
    ],
    include: [
      {
        model: 'course',
        as: 'course',
        attributes: ['id', 'name', 'subjectId'],
      },
    ],
  },
  {
    model: 'stream',
    as: 'stream',
    attributes: ['id', 'num'],
  },
];

export const streamLessonsInclude = [
  {
    model: 'exercise',
    as: 'exercise',
    required: true,
    where: {
      deleted: false,
    },
    attributes: ['id', 'name'],
    include: [
      {
        model: 'course',
        as: 'course',
        attributes: ['id', 'name'],
        where: {
          deleted: false,
        },
      },
    ],
  },
];

export const homeworkInclude = [
  {
    model: 'student_homework_media',
    where: {
      deleted: false
    },
    as: 'medias',
    include: [
      {
        model: 'media',
        as: 'media',
      },
    ],
  },
];

export const mediaInclude = [
  {
    model: 'media',
    as: 'media',
  },
];
